.nav-menu {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    display: flex;
    max-width: 100%;
    justify-content: flex-end;
}

.button {
    background: none;
    border: none;
	font: inherit;
	cursor: pointer;
}

.ig-logo {
    margin-top: 10px;
    width:60%;
}

.nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    width: 20%;
}

.cart {
    width: 80%;
}

.arrow {
    width: 80%;
    min-width: 65px;
}


@media screen and (min-width: 1024px) { 

    .cart {
        width: 40%;
    }

    .arrow {
        padding-top: 5px;
        width: 35%;
    }

    .ig-logo {
    width: 30%;
    margin-top: 20px;
    }
}

